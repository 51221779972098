import * as msal from "@azure/msal-browser";

export const LOGIN_SCOPES = ["openid", "profile", "user.read"];
export const API_SCOPES = [process.env.REACT_APP_API_SCOPES];

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_AUTHORITY_DOMAIN],
    validateAuthority: true,
    postLogoutRedirectUri: process.env.REACT_APP_HOME_PAGE_URL,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: 15000,
    windowHashTimeout: 60000,
  },
};

export const msalApp = new msal.PublicClientApplication(msalConfig);

export const acquireToken = async () => {
  var userRequest = {
    scopes: API_SCOPES,
    //account: msalApp.getAccount(),
    redirectUri: "",
  };

  try {
    // const account = msalApp.get;
    return await msalApp.acquireTokenPopup(userRequest);
  } catch (error) {
    console.log("Error = ", error);
  }
};

export const acquireTokenSilently = async (username = null) => {
  var userRequest = {
    scopes: API_SCOPES,
    redirectUri: "",
  };
  if (username) {
    const account = msalApp.getAccountByUsername(username);
    userRequest.account = account;
  }
  try {
    return await msalApp.acquireTokenSilent(userRequest);
  } catch (e) {
    if (!username) {
      await msalApp.loginPopup(LOGIN_SCOPES);
      return await acquireToken();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  }
};
