import React from "react";
import Icon from "@ant-design/icons";
import SendFileSvg from "./SendFileSvg";

const SendFileIcon = (props) => (
  <Icon
    component={() => <SendFileSvg {...props} />}
    {...props}
  />
);

export default SendFileIcon;
