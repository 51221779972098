import { combineReducers } from "redux";
import loader from "./loader";
import theme from "./theme";
import ColumnOptions from "./columnOptions";
import gridHeight from "./gridHeight";
import mainGrid from "./mainGrid";
import userPreferences from "./userPreferences";

export default combineReducers({
  loader,
  theme,
  ColumnOptions,
  gridHeight,
  mainGrid,
  userPreferences,
});
