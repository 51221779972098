import { createSlice } from "@reduxjs/toolkit";
import { Theme } from "../../theme/theme";
const themeSlice = createSlice({
  name: "theme",
  initialState: { state: { font: "small", themeMode: "light" } },
  reducers: {
    setTheme: (theme, action) => {
      const { state } = action.payload;
      theme.state = Theme[state];
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
