import http from "../../util/httpService";
import * as actions from "../common/api";
import {
  incrementLoaderCount,
  decrementLoaderCount,
} from "../ui/loader"

const api = ({ dispatch }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);
  const { url, method, data, onStart, onSuccess, onReturnProps, onError } = action.payload;

  dispatch(incrementLoaderCount());
  if(onStart) dispatch({type: onStart})
  next(action);

  try {
    const response = await http[method](url, data);
    dispatch(actions.apiCallSuccess(response.data)); // General
    onSuccess && dispatch({ type: onSuccess, payload: { data: response.data, onReturnProps } }); // Specific
    dispatch(decrementLoaderCount());
  } catch (error) {
    dispatch(actions.apiCallFailed(error.message)); // General
    onError && dispatch({ type: onError, payload: error.message }); // Specific
    dispatch(decrementLoaderCount());
  }
};

export default api;
