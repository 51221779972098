import React from "react";
import { Tags } from "@tintelligence/components";
import { OfflineIcon, NonFunctionalIcon, AttentionIcon } from "@tintelligence/icons";
import styled from "styled-components";
import { CloudOutlined } from "../../components/icons";
import moment from "moment-timezone";
import ReactCountryFlag from "react-country-flag";

const IconTag = styled.div`
  display: flex;
  align-items: center;
  padding: 1px;
  font: normal normal 500 12px/20px Montserrat;
  color: ${(props) => props.theme.mode.secondaryText};
  & span.anticon {
    margin-right: 8px;
  }
`;

export const statusOrder = [
  "Non-Functional",
  "Attention-Critical",
  "Attention-Warning",
  "Attention",
  "Offline",
  "Good",
];
export const getStatusTag = (status, statusMessages) => {
  switch (status) {
    case "Good": {
      return statusMessages.good;
    }
    case "Offline": {
      return (
        <Tags
          data={[
            <IconTag>
              <OfflineIcon height={16} /> {statusMessages.offline}
            </IconTag>,
          ]}
          colors={["#AA9C9C"]}
        />
      );
    }
    case "Non-Functional": {
      return (
        <Tags
          data={[
            <IconTag>
              <NonFunctionalIcon height={16} /> {statusMessages.nonFunctional}
            </IconTag>,
          ]}
          colors={["#D51E23"]}
        />
      );
    }
    case "Attention-Critical": {
      return (
        <Tags
          data={[
            <IconTag>
              <AttentionIcon height={16} /> {statusMessages.attentionCritical}
            </IconTag>,
          ]}
          colors={["#FF8900"]}
        />
      );
    }
    case "Attention-Warning": {
      return (
        <Tags
          data={[
            <IconTag>
              <AttentionIcon height={16} /> {statusMessages.attentionWarning}
            </IconTag>,
          ]}
          colors={["#FF8900"]}
        />
      );
    }
    case "Attention": {
      return (
        <Tags
          data={[
            <IconTag>
              <AttentionIcon height={16} /> {statusMessages.attention}
            </IconTag>,
          ]}
          colors={["#FF8900"]}
        />
      );
    }
    case "No Equipment": {
      return statusMessages.noEquipment;
    }
    default: {
      return "-";
    }
  }
};

export const getCustomStatusTag = (status, statusMessages) => {
  switch (status) {
    case "Good": {
      return statusMessages.good;
    }
    case "Online": {
      return (
        <Tags
          data={[
            <IconTag>
              <CloudOutlined height={16} /> {statusMessages.online}
            </IconTag>,
          ]}
        />
      );
    }
    case "Offline": {
      return (
        <Tags
          data={[
            <IconTag>
              <OfflineIcon height={16} /> {statusMessages.offline}
            </IconTag>,
          ]}
          colors={["#AA9C9C"]}
        />
      );
    }
    case "No Equipment": {
      return statusMessages.noEquipment;
    }
    default: {
      return "";
    }
  }
};
export const getStatusCountTag = (status, statusMessages) => {
  switch (status) {
    case "Good": {
      return statusMessages.good;
    }
    case "Offline": {
      return (
        <span style={{ color: "#AA9C9C" }}>
          <OfflineIcon height={16} color={"#AA9C9C"} /> {statusMessages.offline}
        </span>
      );
    }
    case "Non-Functional": {
      return (
        <span style={{ color: "#D51E23" }}>
          <NonFunctionalIcon height={16} color={"#D51E23"} /> {statusMessages.nonFunctional}
        </span>
      );
    }
    case "Attention-Critical": {
      return (
        <span style={{ color: "#FF8900" }}>
          <AttentionIcon height={16} color={"#FF8900"} /> {statusMessages.attentionCritical}
        </span>
      );
    }
    case "Attention-Warning": {
      return (
        <span style={{ color: "#FF8900" }}>
          <AttentionIcon height={16} color={"#FF8900"} /> {statusMessages.attentionWarning}
        </span>
      );
    }
    case "Attention": {
      return (
        <span style={{ color: "#FF8900" }}>
          <AttentionIcon height={16} color={"#FF8900"} /> {statusMessages.attention}
        </span>
      );
    }
    case "No Equipment": {
      return statusMessages.noEquipment;
    }
    default: {
      return "";
    }
  }
};

export const getTiers =
   [
     "core",
     "smart",
     "elite",
     "basic",
     "fleet"
    ];

export const events = [
  { name: "Dispense", id: 1 },
  { name: "Error", id: 2 },
  { name: "Purge", id: 3 },
  { name: "Refill", id: 4 },
  { name: "DriverConfigUpdate", id: 6 },
  { name: "ServicePackUpdateEvent", id: 5 },
  { name: "Initialization", id: 7 },
  { name: "AutodriveChanges", id: 8 },
  { name: "Alert", id: 10 },
  { name: "MachineStatus", id: 11 },
  { name: "ServiceNotes", id: 12 },
];

export const getAllTimezones = () => {
  var timeZones = moment.tz.names();
  var offsetTmz = [];
  for (var i in timeZones) {
    offsetTmz.push({
      key: "(GMT" + moment.tz(timeZones[i]).format("Z") + ") " + timeZones[i],
      value: timeZones[i],
    });
  }
  return offsetTmz.sort();
};

let descendStatusOrder = [
  "Good",
  "Offline",
  "Attention-Warning",
  "Attention-Critical",
  "Non-Functional",
  "No Equipment",
];
let ascendStatusOrder = [
  "Non-Functional",
  "Attention-Critical",
  "Attention-Warning",
  "Offline",
  "Good",
  "No Equipment",
];

export const sortBySeverity = (a, b, sortOrder) => {
  if (a.machineStatus === b.machineStatus) {
    return 0;
  }
  if (sortOrder == "ascend") {
    return ascendStatusOrder.indexOf(a.machineStatus) - ascendStatusOrder.indexOf(b.machineStatus);
  }
  return descendStatusOrder.indexOf(b.machineStatus) - descendStatusOrder.indexOf(a.machineStatus);
};
const suportedLanguage = [
  {
    countryCode: "us",
    value: "en-US",
    displatText: "English (US)",
  },
  {
    countryCode: "fr",
    value: "fr-FR",
    displatText: "French",
  },
];

const prodSuportedLanguage = [
  {
    countryCode: "us",
    value: "en-US",
    displatText: "English (US)",
  },
];

export const getLanguageOptions = () => {
  var suportedLanguageOption = [];
  let langSuport =
    process.env.REACT_APP_SITE_TITLE === "Insight" ? prodSuportedLanguage : suportedLanguage;
  langSuport.forEach((lang) => {
    suportedLanguageOption.push({
      label: (
        <>
          <ReactCountryFlag
            countryCode={lang.countryCode}
            svg
            style={{
              width: "2em",
              height: "2em",
            }}
            title={lang.displatText}
          />
          <span className="label" title={lang.displatText} style={{ marginLeft: "2px" }}>
            {lang.displatText}
          </span>
          {/* <span className="label" style={{ marginLeft: "2px" }}> English (US) </span> */}
        </>
      ),
      value: lang.value,
    });
  });

  return suportedLanguageOption;
};
export const getLabelFromOptions = (options, value) => {
  console.log("options", options, value);
  const selectedOption = options.filter((option) => {
    if (option.colorantDetailsRowId) {
      return parseInt(option.colorantDetailsRowId) === parseInt(value);
    } else if (option.colorantSystemRowId) {
      return parseInt(option.colorantSystemRowId) == parseInt(value);
    } else {
      return parseInt(option.value) === parseInt(value);
    }
  });
  console.log("selectedOption", selectedOption);
  return selectedOption
    ? selectedOption[0]?.label || selectedOption[0]?.code + "-" + selectedOption[0]?.color
    : "";
};

export function toCamelCase(text) {
  return text.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
}