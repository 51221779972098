import React from "react";
import Styled from "styled-components";

const StyledSvg = Styled.svg`
& .a{
  fill:none;
  stroke:#465159;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-width:1px;
}
`;

const DownloadFileSvg = () => (
<StyledSvg xmlns="http://www.w3.org/2000/svg" width="34" height="28" viewBox="0 0 34 34.168">
  <g id="Group_5232" data-name="Group 5232" transform="translate(-1461 -308.832)">
    <g id="Rectangle_3294" data-name="Rectangle 3294" transform="translate(1461 331)" fill="none" stroke="#635ad9" stroke-width="2">
      <path d="M0,0H34a0,0,0,0,1,0,0V8a4,4,0,0,1-4,4H4A4,4,0,0,1,0,8V0A0,0,0,0,1,0,0Z" stroke="none"/>
      <path d="M2,1H32a1,1,0,0,1,1,1V8a3,3,0,0,1-3,3H4A3,3,0,0,1,1,8V2A1,1,0,0,1,2,1Z" fill="none"/>
    </g>
    <g id="Union_59" data-name="Union 59" transform="translate(-20965 -22987.166) rotate(90)" fill="none">
      <path d="M23305-22436v-3h-8a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h8v-3a1,1,0,0,1,1.6-.8l9.332,7a1,1,0,0,1,0,1.6l-9.332,7a.986.986,0,0,1-.6.2A1,1,0,0,1,23305-22436Z" stroke="none"/>
      <path d="M 23306.998046875 -22437.998046875 L 23313.669921875 -22443.001953125 L 23306.998046875 -22448.00390625 L 23306.998046875 -22444.998046875 L 23297.998046875 -22444.998046875 L 23297.998046875 -22440.998046875 L 23306.998046875 -22440.998046875 L 23306.998046875 -22437.998046875 M 23306.00390625 -22434.998046875 C 23305.484375 -22434.998046875 23304.998046875 -22435.408203125 23304.998046875 -22436.001953125 L 23304.998046875 -22438.998046875 L 23296.998046875 -22438.998046875 C 23296.44921875 -22438.998046875 23295.998046875 -22439.44921875 23295.998046875 -22439.998046875 L 23295.998046875 -22446.001953125 C 23295.998046875 -22446.552734375 23296.44921875 -22446.998046875 23296.998046875 -22446.998046875 L 23304.998046875 -22446.998046875 L 23304.998046875 -22450 C 23304.998046875 -22450.82421875 23305.94140625 -22451.29296875 23306.6015625 -22450.80078125 L 23315.93359375 -22443.802734375 C 23316.466796875 -22443.3984375 23316.466796875 -22442.59765625 23315.93359375 -22442.19921875 L 23306.6015625 -22435.201171875 C 23306.4140625 -22435.060546875 23306.20703125 -22434.998046875 23306.00390625 -22434.998046875 Z" stroke="none" fill="#635ad9"/>
    </g>
    <circle id="Ellipse_246" data-name="Ellipse 246" cx="2" cy="2" r="2" transform="translate(1466 335)" fill="#635ad9"/>
    <circle id="Ellipse_247" data-name="Ellipse 247" cx="2" cy="2" r="2" transform="translate(1472 335)" fill="#635ad9"/>
  </g>
</StyledSvg>
);

export default DownloadFileSvg;
