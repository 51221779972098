import { createSlice } from "@reduxjs/toolkit";

const machineListSlice = createSlice({
    name: "machineList",
    initialState: { list: {}},
    reducers: {
        setMachineList: (machineList, action) => {
            machineList.list = action.payload
        }
    }
})

export const { setMachineList } = machineListSlice.actions;
export default machineListSlice.reducer;