import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../common/api";
import { notifier } from "../../components/ui"
import { format, setDate, getDate, getMonth, getYear, differenceInMinutes } from "date-fns";
import moment from "moment";

const getUTCDate = (date) => {
    return setDate(new Date(getYear(date), getMonth(date), 1), getDate(date));
};

const getReplacableMachine = (oldObj) => {
    const countObj = {}
    Object.keys(oldObj).forEach((a, i)=> {
        countObj[oldObj[a].count] = countObj[oldObj[a].count] ? countObj[oldObj[a].count] + 1 : 1
    })

    //get highest count sort order arr
    const highest = Object.keys(oldObj).sort((a, b) => {
        return oldObj[b].count - oldObj[a].count
    })
    let len = Object.keys(countObj).length
    let sliceCount = len >= 3 ? 2 : len == 2 ? 1 : 0;
    const remaining = highest.slice(sliceCount);
    const newObj = {};
    remaining.map(a => (
        newObj[a] = oldObj[a]
    ))
    const oldest  = Object.keys(newObj).sort((a, b) => {
        return oldObj[a].timeStamp - oldObj[b].timeStamp
    })
    return oldest[0]
}

// export const getTimelineEvents = (data) => (dispatch, getState) => {
//     const { machineId, startDate, endDate , filterEventsId , selectedTier } = data
//     const currentState = getState().entities.timelineEvents;
//     const cache = getState().entities.timelineEvents.cache;    
//     const fetchTimelineData = (sDate = startDate, eDate = endDate, append = "") => {
//         !(Number.isNaN(startDate)) && !(Number.isNaN(endDate)) && 
//         dispatch(apiCallBegan({
//             url: `/ms/api/timeline/timelineLoczEvent`,
//             method: 'post',
//             data: {
//                 "machineRowId": machineId,
//                 "unixStartDate": sDate,
//                 "unixEndDate": eDate,
//                 "eventTypes": filterEventsId,
//                 "Tier":selectedTier,
//                 "pagingResponse":true,
//                 // "pageNumber": 0,
//                 // "pageSize": 0,
                
//               },
//             onSuccess: eventDataReceived,
//             onReturnProps: { 
//                 machineId, cache, oEvents: currentState[machineId]?.events, append, 
//                 dates: { startDate: currentState[machineId]?.startDate, endDate: currentState[machineId]?.endDate }
//             },
//             onError: eventDataRequestFailed
//         }))
//     }
    
//     if(currentState[machineId] !== undefined){
//         if(new Date(currentState[machineId].startDate) <= new Date(getUTCDate(startDate)) && new Date(getUTCDate(endDate)) <= new Date(currentState[machineId].endDate)){
//             const endOfDay = new Date();
//             endOfDay.setDate(endOfDay.getDate()+1)
//             const current = new Date();
//             if(differenceInMinutes(current, currentState[machineId].lastSync) > 5){
//                 fetchTimelineData();
//             } 
//         } else {
//             if(new Date(currentState[machineId].startDate) > new Date(getUTCDate(startDate))){
//                 fetchTimelineData(startDate, +new Date(currentState[machineId].startDate), 'pre');
//             }
//             if(new Date(getUTCDate(endDate)) > new Date(currentState[machineId].endDate)) {
//                 fetchTimelineData( +new Date(currentState[machineId].endDate) + 19800000, endDate, 'post');
//             }
//         }
//     } else {
//         machineId !== 0 && fetchTimelineData()
//     }
// }

const timelineEventsSlice = createSlice({
    name: "timelineEvents",
    initialState: { currentDate: new Date(), data: {} },
    reducers: {
        setTimelineEvents: (timelineEvents, action) => {
            console.log("action",action.payload);
            // const { eventDetails, pagingMetadata } = action.payload;
            timelineEvents.data = action.payload 
            //notifier.success('Timeline data is updated...')
        },
        clearCacheOnRefresh: (timelineEvents, action) => {
            const { machineId } = action.payload;
            delete timelineEvents[machineId]
            //notifier.success('refresh...')
        },
        // eventDataReceived: (timelineEvents, action) => {
        //     const { onReturnProps, data } = action.payload;
        //     const localEvents = data.eventDetails.map((d) => ({
        //         ...d,
        //       }));
        //     const mergedEvents = onReturnProps.oEvents !== undefined 
        //     ? onReturnProps.append == 'pre' 
        //     ? [...onReturnProps.oEvents, ...localEvents] 
        //     : [...localEvents, ...onReturnProps.oEvents] 
        //     : localEvents;

        //     timelineEvents.cache[onReturnProps.machineId] = {}
        //     timelineEvents.cache[onReturnProps.machineId].timeStamp = + new Date()

        //     let obj = {
        //         lastSync: new Date(), 
        //         dataValidToDisplay: true,
        //         timeStamp: + new Date()
        //     }

        //     if(Object.entries(onReturnProps.cache).length < 5) {
        //         timelineEvents[onReturnProps.machineId] = { 
        //             events: mergedEvents, 
        //             startDate: onReturnProps.append == 'post' ? onReturnProps.dates.startDate : data.startDate, 
        //             endDate: onReturnProps.append == 'pre' ? onReturnProps.dates.endDate :  data.endDate,
        //             ...obj
        //           };
        //         timelineEvents.cache[onReturnProps.machineId].count = onReturnProps.cache?.[onReturnProps.machineId]?.count + 1 || 1;
        //     }
        //     else {
        //         if(onReturnProps.cache[onReturnProps.machineId] !== undefined){
        //             timelineEvents[onReturnProps.machineId] = { 
        //                 events: mergedEvents, 
        //                 startDate: data.startDate, 
        //                 endDate: data.endDate,
        //                 ...obj
        //               };
        //             timelineEvents.cache[onReturnProps.machineId].count = onReturnProps.cache?.[onReturnProps.machineId]?.count + 1 || 1;
        //         }
        //         else {
        //             //calculate and return
        //             let replaceId = getReplacableMachine(onReturnProps.cache)
        //             delete timelineEvents.cache[replaceId];
        //             delete timelineEvents[replaceId];

        //             timelineEvents[onReturnProps.machineId] = {
        //                 events: localEvents, 
        //                 startDate: data.startDate, 
        //                 endDate: data.endDate,
        //                 ...obj
        //             }
        //             timelineEvents.cache[onReturnProps.machineId].count = 1;
        //         }
        //     }
        // },
        // eventDataRequestFailed: (timelineEvents, action) => {
        //     notifier.warn('Error occured while fetching data')
        // }
    },
})

export const { setTimelineEvents, clearCacheOnRefresh, eventDataReceived, eventDataRequestFailed } = timelineEventsSlice.actions;
export default timelineEventsSlice.reducer;