import { createSlice } from "@reduxjs/toolkit";

const getColumnOptionsForMachines = () => {
  let options = [];
  if (localStorage.getItem("machineColumnOptions") === null) {
    localStorage.setItem("machineColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("machineColumnOptions"));
  return options;
};
const getColumnOptionsForCoreMachines = () => {
  let options = [];
  if (localStorage.getItem("CoreMachineColumnOptions") === null) {
    localStorage.setItem("CoreMachineColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("CoreMachineColumnOptions"));
  return options;
};
const getColumnOptionsForSmartMachines = () => {
  let options = [];
  if (localStorage.getItem("SmartMachineColumnOptions") === null) {
    localStorage.setItem("SmartMachineColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("SmartMachineColumnOptions"));
  return options;
};
const getColumnOptionsForEliteMachines = () => {
  let options = [];
  if (localStorage.getItem("EliteMachineColumnOptions") === null) {
    localStorage.setItem("EliteMachineColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("EliteMachineColumnOptions"));
  return options;
};
const getColumnOptionsForFleetMachines = () => {
  let options = [];
  if (localStorage.getItem("FleetMachineColumnOptions") === null) {
    localStorage.setItem("FleetMachineColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("FleetMachineColumnOptions"));
  return options;
};
const getColumnOptionsForBasicMachines = () => {
  let options = [];
  if (localStorage.getItem("BasicMachineColumnOptions") === null) {
    localStorage.setItem("BasicMachineColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("BasicMachineColumnOptions"));
  return options;
};
const getColumnOptionsForCoreShops = () => {
  let options = [];
  if (localStorage.getItem("CoreMachineColumnOptions") === null) {
    localStorage.setItem("CoreMachineColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("CoreMachineColumnOptions"));
  return options;
};

const getColumnOptionsForSmartShops = () => {
  let options = [];
  if (localStorage.getItem("SmartShopColumnOptions") === null) {
    localStorage.setItem("SmartShopColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("SmartShopColumnOptions"));
  return options;
};
const getColumnOptionsForEliteShops = () => {
  let options = [];
  if (localStorage.getItem("EliteShopColumnOptions") === null) {
    localStorage.setItem("EliteShopColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("EliteShopColumnOptions"));
  return options;
};
const getColumnOptionsForFleetShops = () => {
  let options = [];
  if (localStorage.getItem("FleetShopColumnOptions") === null) {
    localStorage.setItem("FleetShopColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("FleetShopColumnOptions"));
  return options;
};


const getColumnOptionsForBasicShops = () => {
  let options = [];
  if (localStorage.getItem("BasicShopColumnOptions") === null) {
    localStorage.setItem("BasicShopColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("BasicShopColumnOptions"));
  return options;
};


const getColumnOptionsForUsers = () => {
  let options = [];
  if (localStorage.getItem("userColumnOptions") === null) {
    localStorage.setItem("userColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("userColumnOptions"));
  return options;
};

const getColumnOptionsForLicenses = () => {
  let options = [];
  if (localStorage.getItem("licensesColumnOptions") === null) {
    localStorage.setItem("licensesColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("licensesColumnOptions"));
  return options;
};
const getColumnOptionsForLicenseDetails = () => {
  let options = [];
  if (localStorage.getItem("licensesDetailsColumnOptions") === null) {
    localStorage.setItem("licensesDetailsColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("licensesDetailsColumnOptions"));
  return options;
};

const getColumnOptionsForCustomers = () => {
  let options = [];
  if (localStorage.getItem("customerColumnOptions") === null) {
    localStorage.setItem("customerColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("customerColumnOptions"));
  return options;
};

const getColorantDetails = () => {
  let options = [];
  if(localStorage.getItem("colorantColumnOptions") === null) {
    localStorage.setItem("colorantColumnOptions", JSON.stringify(options))
  }
  options = JSON.parse(localStorage.getItem("colorantColumnOptions"));
  return options
}

const getColumnOptionsForMachineConfigration = () => {
  let options = [];
  if (localStorage.getItem("machineConfigrationColumnOptions") === null) {
    localStorage.setItem("machineConfigrationColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("machineConfigrationColumnOptions"));
  return options;
};

const getColumnOptionsForPumpSettings = () => {
  let options = [];
  if (localStorage.getItem("pumpSettingsColumnOptions") === null) {
    localStorage.setItem("pumpSettingsColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("pumpSettingsColumnOptions"));
  return options;
};
const getColumnOptionsForConfigurationMaps = () => {
  let options = [];
  if (localStorage.getItem("configurationMapsColumnOptions") === null) {
    localStorage.setItem("configurationMapsColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("configurationMapsColumnOptions"));
  return options;
};

const columnOptionsSlice = createSlice({
  name: "columnOptions",
  initialState: {
    customer: null,
    shops: { 
      core: { columns: getColumnOptionsForCoreShops() },
      smart: { columns: getColumnOptionsForSmartShops() },
      elite: { columns: getColumnOptionsForEliteShops() },     
      fleet: { columns: getColumnOptionsForFleetShops() },     
      basic: { columns: getColumnOptionsForBasicShops() },

    },
    machines: { 
      core: { columns: getColumnOptionsForCoreMachines() },
      smart: { columns: getColumnOptionsForSmartMachines() },
      elite: { columns: getColumnOptionsForEliteMachines() },     
      fleet: { columns: getColumnOptionsForFleetMachines() },     
      basic: { columns: getColumnOptionsForBasicMachines() },

    },
    // machines: { columns: getColumnOptionsForMachines() },
    users: { columns: getColumnOptionsForUsers() },
    customers: { columns: getColumnOptionsForCustomers() },
    colorants: { columns: getColorantDetails() },
    licenses: { columns: getColumnOptionsForLicenses() },
    licenseDetails : { columns : getColumnOptionsForLicenseDetails() },
    machineConfigration : {columns : getColumnOptionsForMachineConfigration() },
    pumpSettings: { columns : getColumnOptionsForPumpSettings() },
    configurationMaps :{ columns : getColumnOptionsForConfigurationMaps() },
  },
  reducers: {
    setColumnsOptions: (colState, action) => {
      const { type, columns = [], selectedTier = null, selectedCustomer = null } = action.payload;
      if(selectedCustomer) colState.customer = selectedCustomer;
      if (!colState[type]) colState[type] = {};
      selectedTier ? colState[type][selectedTier].columns = columns :  colState[type].columns = columns;

      // type === "machines" &&
      // localStorage.setItem("machineColumnOptions", JSON.stringify(columns));
      type === "machines" && selectedTier === 'elite' &&
      localStorage.setItem("EliteMachineColumnOptions", JSON.stringify(columns));
      type === "machines" && selectedTier === 'core' &&
      localStorage.setItem("CoreMachineColumnOptions", JSON.stringify(columns));
      type === "machines" && selectedTier === 'smart' &&
      localStorage.setItem("SmartMachineColumnOptions", JSON.stringify(columns));
      type === "machines" && selectedTier === 'basic' &&
      localStorage.setItem("BasicMachineColumnOptions", JSON.stringify(columns));
      type === "machines" && selectedTier === 'fleet' &&
      localStorage.setItem("FleetMachineColumnOptions", JSON.stringify(columns));
      type === "shops" && selectedTier === 'elite' &&
      localStorage.setItem("EliteShopColumnOptions", JSON.stringify(columns));
      type === "shops" && selectedTier === 'smart' &&
        localStorage.setItem("SmartShopColumnOptions", JSON.stringify(columns));
      type === "shops" && selectedTier === 'core' &&
        localStorage.setItem("CoreShopColumnOptions", JSON.stringify(columns));
        type === "shops" && selectedTier === 'basic' &&
        localStorage.setItem("basicShopColumnOptions", JSON.stringify(columns));
      type === "users" &&
        localStorage.setItem("userColumnOptions", JSON.stringify(columns));
      type === "customers" &&
        localStorage.setItem("customerColumnOptions", JSON.stringify(columns));
      type === "colorants" &&
        localStorage.setItem("colorantColumnOptions", JSON.stringify(columns));
      type === "licenses" &&
      localStorage.setItem("licensesColumnOptions", JSON.stringify(columns));
      type === "licenseDetails" &&
      localStorage.setItem("licensesDetailsColumnOptions", JSON.stringify(columns));
      type === "machineConfigration" &&
      localStorage.setItem("machineConfigrationColumnOptions", JSON.stringify(columns));
      type === "pumpSettings" &&
      localStorage.setItem("pumpSettingsColumnOptions", JSON.stringify(columns));
      type === "configurationMaps" &&
      localStorage.setItem("configurationMapsColumnOptions", JSON.stringify(columns));
    },
  },
});

export const { setColumnsOptions } = columnOptionsSlice.actions;
export default columnOptionsSlice.reducer;
