import React from "react";
import Icon from "@ant-design/icons";
import DownloadFileSvg from "./DownloadFileSvg";

const DownloadFileIcon = (props) => (
  <Icon
    component={() => <DownloadFileSvg {...props} />}
    {...props}
  />
);

export default DownloadFileIcon;
