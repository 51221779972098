import { createSlice } from "@reduxjs/toolkit";

const PowerBICredsSlice = createSlice({
  name: "powerBICreds",
  initialState: {
    latestCallDateTime: null, data: {},
  },
  reducers: {
    setPowerBICreds: (powerBICreds, action) => {
      const { data } = action.payload;
      powerBICreds.data = data;
      powerBICreds.latestCallDateTime = new Date().getTime();
    },
  },
});

export const { setPowerBICreds } = PowerBICredsSlice.actions;
export default PowerBICredsSlice.reducer;
