import { createSlice } from "@reduxjs/toolkit";

const servicePackStatusSlice = createSlice({
  name: "servicePackStatus",
  initialState: { list: [] },
  reducers: {
    setServicePackStatusList: (serivcePackStatus, action) => {
      serivcePackStatus.list = action.payload;
    },
  },
});

export const { setServicePackStatusList } = servicePackStatusSlice.actions;
export default servicePackStatusSlice.reducer;
