import React from "react";
import Styled from "styled-components";

const StyledSvg = Styled.svg`
& .a{
  fill:none;
  stroke:#465159;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-width:1px;
}
`;

const SendFileSvg = () => (
<StyledSvg xmlns="http://www.w3.org/2000/svg" width="29.332" height="28" viewBox="0 0 29.332 34">
  <g id="Group_5226" data-name="Group 5226" transform="translate(-379 -299)">
    <g id="Rectangle_3282" data-name="Rectangle 3282" transform="translate(379 299)" fill="#fff" stroke="#635ad9" stroke-width="2">
      <rect width="26" height="34" rx="4" stroke="none"/>
      <rect x="1" y="1" width="24" height="32" rx="3" fill="none"/>
    </g>
    <g id="Path_6468" data-name="Path 6468" transform="translate(400 307)" fill="#fff">
      <path d="M 0.5 18.1384105682373 L 0.5 -0.1384114325046539 L 6.5 3.290159940719604 L 6.5 14.70983982086182 L 0.5 18.1384105682373 Z" stroke="none"/>
      <path d="M 1 0.7231769561767578 L 1 17.27682304382324 L 6 14.41967964172363 L 6 3.580320358276367 L 1 0.7231769561767578 M 0 -1 L 7 3 L 7 15 L 0 19 L 0 -1 Z" stroke="none" fill="#fff"/>
    </g>
    <g id="Union_58" data-name="Union 58" transform="translate(408.334 324.002) rotate(180)" fill="none">
      <path d="M9,15V12H1a1,1,0,0,1-1-1V5A1,1,0,0,1,1,4H9V1A1,1,0,0,1,10.6.2l9.333,7a1,1,0,0,1,0,1.6l-9.333,7a.987.987,0,0,1-.6.2A1,1,0,0,1,9,15Z" stroke="none"/>
      <path d="M 11.00180053710938 13.00227642059326 L 17.66853904724121 8.00190258026123 L 11.00180053710938 3.001531600952148 L 11.00180053710938 6.00230073928833 L 2.001800537109375 6.00230073928833 L 2.001800537109375 10.00240039825439 L 11.00180053710938 10.00240039825439 L 11.00180053710938 13.00227642059326 M 10.00618076324463 16.00376129150391 C 9.48783016204834 16.00376129150391 9.001800537109375 15.59409046173096 9.001800537109375 15.00210094451904 L 9.001800537109375 12.00240039825439 L 1.001700520515442 12.00240039825439 C 0.4500004649162292 12.00240039825439 0.001800472964532673 11.55420112609863 0.001800472964532673 11.00160121917725 L 0.001800472964532673 5.002201080322266 C 0.001800472964532673 4.449600696563721 0.4500004649162292 4.00230073928833 1.001700520515442 4.00230073928833 L 9.001800537109375 4.00230073928833 L 9.001800537109375 1.001700878143311 C 9.001800537109375 0.1782012283802032 9.942300796508789 -0.2924996614456177 10.60200023651123 0.2016008794307709 L 19.93500137329102 7.20180082321167 C 20.46870040893555 7.602300643920898 20.46870040893555 8.402400970458984 19.93500137329102 8.802000999450684 L 10.60200023651123 15.80220127105713 C 10.41654014587402 15.94111061096191 10.20888042449951 16.00376129150391 10.00618076324463 16.00376129150391 Z" stroke="none" fill="#635ad9"/>
    </g>
  </g>
</StyledSvg>
);

export default SendFileSvg;
