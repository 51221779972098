import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../common/api";
import { differenceInMinutes } from "date-fns";
import { groupBy } from "../../util/helper";
import { startCase } from "lodash";
import { basicMachineList } from "./test";

export const getMachinesList = ({ tier, refresh = false, selectedCustomer }) => (dispatch, getState) => {
    const currentState = getState().entities.Machines;
    
    const fetchMachinesList = () => {
        dispatch(apiCallBegan({
            url: `/om/api/machine/machineDetails/${tier}?selectedCustomerId=${selectedCustomer}`,
            method: 'get',
            onSuccess: eventDataReceived,
            onReturnProps: { tier, selectedCustomer },
            //onError: eventDataRequestFailed
        }))
    }

    if(currentState[tier].list.length > 0) {
        if(currentState.machineCustomer != selectedCustomer || differenceInMinutes(new Date(), currentState[tier].lastSync) > 5){
            fetchMachinesList();
        } 
        if(refresh){
          fetchMachinesList();  
        }
    } else fetchMachinesList()   
}


const machineSlice = createSlice({
    name: "machines",
    initialState: {
        machineCustomer: "",
        core: {
            lastSync: new Date(),
            list: [],
            listBySeries: {}
        },
        smart: {
            lastSync: new Date(),
            list: [],
            listBySeries: {}
        },
        fleet: {
            lastSync: new Date(),
            list: [],
            listBySeries: {}
        },
        elite: {
            lastSync: new Date(),
            list: [],
            listBySeries: {}
        },
        basic: {
            lastSync: new Date(),
            list: [],
            listBySeries: {}
        },
    },
    reducers: {
        eventDataReceived: (machines, action) => {
            const { onReturnProps, data } = action.payload;
            machines.machineCustomer = onReturnProps.selectedCustomer;
            const newMachines = data.map((m) => ({
                ...m,
                shopName: !m.shopName ? "-" : m.shopName,
                machineType: m.machineType.replace(/\||~/g, "/"),
            }));
            
            // if (onReturnProps.tier == "basic") {
            //   machines[onReturnProps.tier].list = basicMachineList;
            //   machines[onReturnProps.tier].listBySeries = groupBy(
            //     basicMachineList,
            //     "machineSeriesRowId"
            //   );
            // } else {
              machines[onReturnProps.tier].list = newMachines;
              machines[onReturnProps.tier].listBySeries = groupBy(
                newMachines,
                "machineSeriesRowId"
              );
            // }
        }
    }
});

export const { eventDataReceived } = machineSlice.actions;
export default machineSlice.reducer;