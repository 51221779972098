import { createSlice } from "@reduxjs/toolkit";
import { notifier } from "@tintelligence/components";
import { getTiers } from "../../routes/common/util";
import { apiCallBegan } from "../common/api";

export const getShopGroups =
  (selectedTier, selectedCustomer) => (dispatch, getState) => {
    const currentState = getState().entities.shopGroups[selectedTier];
    const fetchShopsGroups = async () => {
      const machineSeriesId = 0;
      dispatch(
        apiCallBegan({
          url: `/om/api/shopgroup/shopGroupHierarchy?selectedCustomerId=${selectedCustomer}`,
          method: "get",
          onSuccess: setShopGroups,
          onReturnProps: { tier: selectedTier, customer: selectedCustomer },
          onError: () => notifier.error("Could not fetch Shop Groups"),
        })
      );
    };
    fetchShopsGroups();
    // if (!currentState.length) {
    //   fetchShopsGroups();
    // }
  };

const shopGroupsSlice = createSlice({
  name: "shopGroups",
  initialState: {
    core: {},
    smart: {},
    elite: {},
    fleet: {},
    basic: {},
  },
  reducers: {
    setShopGroups: (shopGroups, action) => {
      const { data, onReturnProps } = action.payload;
      const parentShopGroup = data.find((s) => s.parentId === 0);
      // if(onReturnProps.tier == getTiers[0] || onReturnProps.tier == getTiers[3] || onReturnProps.tier == getTiers[4]) {
      if (
        onReturnProps.tier == getTiers[0] ||
        onReturnProps.tier == getTiers[3]
      ) {
        shopGroups[onReturnProps.tier][onReturnProps.customer] = [
          parentShopGroup,
        ]; //for core tier, only parentshopgroup showing
      } else {
        shopGroups[onReturnProps.tier][onReturnProps.customer] = [...data];
      }
    },
  },
});

export const { setShopGroups } = shopGroupsSlice.actions;
export default shopGroupsSlice.reducer;
