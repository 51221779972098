export const Theme = {
  fontSizes: {
    small: {
      fontFamily: 'Montserrat',
      pFont: "normal normal 500 14px/16px Montserrat",
    },
    large: {
      fontFamily: 'Montserrat',
      pFont: "normal normal 500 14px/16px Montserrat",
    },
  },
  mode: {
    light: {
      mainBg: "#F4F4F3",
      primary: "#FFFFFF",
      secondary: "#635AD9",
      activeSecondary: "#5141AE",
      primaryText: "#333333",
      secondaryText: "#FFFFFF",
      lightSecondary: "#e8e6fe",
      boxShadow: '1px 0px 6px rgb(0 0 0 / 25%)',
      disabled:'#d9d9d9'
    },
    dark: {
      mainBg: "#1A1A1A",
      primary: "#292828",
      secondary: "#635AD9",
      activeSecondary: "#5141AE",
      primaryText: "#8f8f8f",
      secondaryText: "#FFFFFF",
      lightSecondary: "#e8e6fe",
      boxShadow: '0px 2px 10px rgb(0 0 0 / 90%)',
      disabled:'#d9d9d9'
    },
  },
};
