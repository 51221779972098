import { createSlice, createSelector } from "@reduxjs/toolkit";

const machineSeriesSlice = createSlice({
  name: "machineSeries",
  initialState: {
    defaultVal: "1",
    list: [],
    allList: [],
    selectedSeriesByTier: {
      core: null,
      smart: null,
      elite: null,
      fleet:null,
      basic:null,
    },
  },
  reducers: {
    setMachineSeriesDefaultValue: (machineSeries, action) => {
      machineSeries.defaultVal = action.payload;
    },
    setMachineSeriesList: (machineSeries, action) => {
      machineSeries.list = action.payload;
    },
    setAllMachineSeriesList: (machineSeries, action) => {
      machineSeries.allList = action.payload;
    },
    setSelectedMachineSeriesByTier: (machineSeries, action) => {
      const { tier, series } = action.payload;
      machineSeries.selectedSeriesByTier[tier] = series;
    },
  },
});

export const {
  setMachineSeriesList,
  setMachineSeriesDefaultValue,
  setAllMachineSeriesList,
  setSelectedMachineSeriesByTier
} = machineSeriesSlice.actions;

export const getMachineSeriesByTier = createSelector(
  (state) => state.entities.machineSeries.list,
  (state) => state.entities.tiers.selectedTier,
  (machineSeriesList, selectedTier) =>
    machineSeriesList.filter((m) =>
      m.tierAvalibility.find((t) => t.toLowerCase() === selectedTier)
    )
);

export default machineSeriesSlice.reducer;
