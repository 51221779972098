export const getFilteredShopsData = (
  shops = [],
  filterOptions = {},
  filterWithSearchText = false
) => {
  let filteredDataSource = shops;
  if (filterOptions.shopStatus) {
    filteredDataSource = filteredDataSource.filter(
      (s) => s.shopStatus === filterOptions.shopStatus
    );
  }
  if (filterOptions.shopStatusArr) {
    if(!filterOptions.shopStatusArr.includes('All')) {
      var newArr = [];
      filteredDataSource = filteredDataSource.filter((s) => {
        filterOptions.shopStatusArr.forEach((k) => {
          if(s.status.includes(k)){
            newArr.push({ ...s })
          }
        })
      }
      )
      filteredDataSource = [...newArr];
    } else filteredDataSource = filteredDataSource
  }
  if (filterOptions.shopGroup) {
    filteredDataSource = filteredDataSource.filter(
      (s) => s.shopGroupName === filterOptions.shopGroup
    );
  }
  if (filterWithSearchText && filterOptions.searchText) {
    const { searchText } = filterOptions;
    const columns = Object.keys(shops[0]);
    filteredDataSource = filteredDataSource.filter((shop) => {
      let isValidRow = false;
      for (let i = 0; i < columns.length; i++) {
        if (
          ("" + shop[columns[i]])
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        ) {
          isValidRow = true;
          break;
        }
      }
      return isValidRow;
    });
  }
  return filteredDataSource;
};

export const coreShopStatus = ["No Equipment", "Non-Functional", "Good", "Offline"];
export const nonCoreShopStatus = ["No Equipment", "Attention", "Non-Functional", "Good", "Offline"]