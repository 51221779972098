import { createSlice } from "@reduxjs/toolkit";

const LicenseListSlice = createSlice({
    name: "licenseList",
    initialState: { list: []},
    reducers: {
        setLicenseList: (licenseList, action) => {
            licenseList.list = action.payload
        }
    }
})

export const { setLicenseList } = LicenseListSlice.actions;
export default LicenseListSlice.reducer;