import { createSlice } from "@reduxjs/toolkit";

const getdimensionForGrid = () => {
  let options = { height: 0, width: 0 };
  if (localStorage.getItem("gridDimension") === null) {
    localStorage.setItem("gridDimension", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("gridDimension"));
  return options;
};

const mainGridSlice = createSlice({
  name: "mainGrid",
  initialState: { state: getdimensionForGrid() },
  reducers: {
    setMainGrid: (mainGrid, action) => {
      const { width, height } = action.payload;
        mainGrid.state.width = width;
        mainGrid.state.height = height;
        let newObj = {
          height,
          width
        }
        localStorage.setItem("gridDimension", JSON.stringify(newObj));
    }
  },
});

export const { setMainGrid } = mainGridSlice.actions;
export default mainGridSlice.reducer;