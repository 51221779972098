import { createSlice } from "@reduxjs/toolkit";

const servicePackAvailabilitySlice = createSlice({
    name: "servicePackAvailability",
    initialState: { list: []},
    reducers: {
        setServicePackAvailability: (servicePackAvailability, action) => {
            servicePackAvailability.list = [...action.payload]
        }
    }
})

export const { setServicePackAvailability } = servicePackAvailabilitySlice.actions;
export default servicePackAvailabilitySlice.reducer;