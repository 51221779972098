import { createSlice } from "@reduxjs/toolkit";
import { differenceInMinutes } from "date-fns";
import { notifier } from "@tintelligence/components";
import { apiCallBegan } from "../common/api";

export const getShopGroupAndShopHierarchy =
  (selectedTier, reloadData = false, selectedCustomer) =>
  (dispatch, getState) => {
    const currentState = getState().entities.shopGroupAndShopHierarchy;

    const fetchShopGroupAndShopHierarchy = async () => {
      dispatch(
        apiCallBegan({
          url: `/om/api/shopgroup/shopGroupAndShopHierarchy/${selectedTier}?selectedCustomerId=${selectedCustomer}`,
          method: "get",
          onSuccess: setShopGroupAndShopHierarchy,
          onReturnProps: { tier: selectedTier, selectedCustomer },
          onError: requestFailed,
          //onError: () => notifier.error("Could not fetch package Files Instances"),
        })
      );
    };

    fetchShopGroupAndShopHierarchy();

    // if (differenceInMinutes(new Date(), currentState.lastSync) > 1) {
    //   fetchShopGroupAndShopHierarchy();
    // } else if (
    //   reloadData ||
    //   !currentState ||
    //   !currentState.list ||
    //   currentState.list.length == 0
    // ) {
    //   fetchShopGroupAndShopHierarchy();
    // }
  };

const shopGroupAndShopHierarchySlice = createSlice({
  name: "shopGroupAndShopHierarchy",
  initialState: {
    core: {},
    smart: {},
    elite: {},
    fleet:{},
    basic: {},

  },
    
  reducers: {
    setShopGroupAndShopHierarchy: (shopGroupAndShopHierarchy, action) => {
      const { onReturnProps, data, tier, selectedCustomer } = action.payload;
      if (onReturnProps) {
        return {
          ...shopGroupAndShopHierarchy,
          [onReturnProps.tier]: {
            ...shopGroupAndShopHierarchy[onReturnProps.tier],
            [onReturnProps.selectedCustomer]: {
              list: data,
              lastSync: new Date(),
            },
          },
        };
      } else {
        return {
          ...shopGroupAndShopHierarchy,
          [tier]: {
            ...shopGroupAndShopHierarchy[tier],
            [selectedCustomer]: {
              list: data,
              lastSync: new Date(),
            },
          },
        };
      }
    },
    updateShopGroupAndShopHierarchy: (shopGroupAndShopHierarchy, action) => {
      //update later
      const { tier, data, selectedCustomer } = action.payload;
      return {
        ...shopGroupAndShopHierarchy,
        [tier]: {
          ...shopGroupAndShopHierarchy[tier],
          [selectedCustomer]: {
            list: data,
            lastSync: new Date(),
          },
        },
      };
    },
    deleteShopGroupAndShopHierarchy: (shopGroupAndShopHierarchy, action) => {
      const { tier, data, selectedCustomer } = action.payload;
      if (shopGroupAndShopHierarchy[tier]?.[selectedCustomer]) {
        const newUpdatedData = shopGroupAndShopHierarchy[tier][selectedCustomer]?.list.filter(
          (d) => d.entityId !== data.entityId
        );

        return {
          ...shopGroupAndShopHierarchy,
          [tier]: {
            ...shopGroupAndShopHierarchy[tier],
            [selectedCustomer]: {
              list: newUpdatedData,
              lastSync: new Date(),
            },
          },
        };
      }
    },
    clearShopGroupAndShopHierarchy: (shopGroupAndShopHierarchy, action) => {
      const { tier, selectedCustomer } = action.payload;
      return {
        ...shopGroupAndShopHierarchy,
        [tier]: {
          ...shopGroupAndShopHierarchy[tier],
          [selectedCustomer]: {
            list: [],
            lastSync: new Date(),
          },
        },
      };
    },
    requestFailed: (shopGroupAndShopHierarchy, action) => {
      notifier.warn("Error occured while fetching data");
    },
  },
});

export const {
  setShopGroupAndShopHierarchy,
  clearShopGroupAndShopHierarchy,
  updateShopGroupAndShopHierarchy,
  deleteShopGroupAndShopHierarchy,
  requestFailed,
} = shopGroupAndShopHierarchySlice.actions;
export default shopGroupAndShopHierarchySlice.reducer;
