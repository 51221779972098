import React from "react";
import Styled from "styled-components";

const StyledSvg = Styled.svg`
& .a{
  fill:none;
  stroke:#465159;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-width:1px;
}
`;

const ReceiveFileSvg = () => (
<StyledSvg xmlns="http://www.w3.org/2000/svg" width="29.334" height="28" viewBox="0 0 29.334 34">
  <g id="Group_5227" data-name="Group 5227" transform="translate(-419 -299)">
    <g id="Rectangle_3280" data-name="Rectangle 3280" transform="translate(419 299)" fill="#fff" stroke="#635ad9" stroke-width="2">
      <rect width="26" height="34" rx="4" stroke="none"/>
      <rect x="1" y="1" width="24" height="32" rx="3" fill="none"/>
    </g>
    <g id="Path_6467" data-name="Path 6467" transform="translate(440 307)" fill="#fff">
      <path d="M 0.5 18.1384105682373 L 0.5 -0.1384114325046539 L 6.5 3.290159940719604 L 6.5 14.70983982086182 L 0.5 18.1384105682373 Z" stroke="none"/>
      <path d="M 1 0.7231769561767578 L 1 17.27682304382324 L 6 14.41967964172363 L 6 3.580320358276367 L 1 0.7231769561767578 M 0 -1 L 7 3 L 7 15 L 0 19 L 0 -1 Z" stroke="none" fill="#fff"/>
    </g>
    <g id="Union_57" data-name="Union 57" transform="translate(-22868 22759)" fill="none">
      <path d="M23305-22436v-3h-8a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h8v-3a1,1,0,0,1,1.6-.8l9.332,7a1,1,0,0,1,0,1.6l-9.332,7a.986.986,0,0,1-.6.2A1,1,0,0,1,23305-22436Z" stroke="none"/>
      <path d="M 23306.998046875 -22437.998046875 L 23313.669921875 -22443.001953125 L 23306.998046875 -22448.00390625 L 23306.998046875 -22444.998046875 L 23297.998046875 -22444.998046875 L 23297.998046875 -22440.998046875 L 23306.998046875 -22440.998046875 L 23306.998046875 -22437.998046875 M 23306.00390625 -22434.998046875 C 23305.484375 -22434.998046875 23304.998046875 -22435.408203125 23304.998046875 -22436.001953125 L 23304.998046875 -22438.998046875 L 23296.998046875 -22438.998046875 C 23296.44921875 -22438.998046875 23295.998046875 -22439.44921875 23295.998046875 -22439.998046875 L 23295.998046875 -22446.001953125 C 23295.998046875 -22446.552734375 23296.44921875 -22446.998046875 23296.998046875 -22446.998046875 L 23304.998046875 -22446.998046875 L 23304.998046875 -22450 C 23304.998046875 -22450.82421875 23305.94140625 -22451.29296875 23306.6015625 -22450.80078125 L 23315.93359375 -22443.802734375 C 23316.466796875 -22443.3984375 23316.466796875 -22442.59765625 23315.93359375 -22442.19921875 L 23306.6015625 -22435.201171875 C 23306.4140625 -22435.060546875 23306.20703125 -22434.998046875 23306.00390625 -22434.998046875 Z" stroke="none" fill="#635ad9"/>
    </g>
  </g>
</StyledSvg>
);

export default ReceiveFileSvg;
