import React from "react";
import Styled from "styled-components";

const StyledSvg = Styled.svg`
    & .a {
      fill:none;
      stroke:#465159;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-width:1px;
    }
`;

const AutoDriveChangesSvg = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="25"
    viewBox="0 0 17.5 17.502"
  >
    <g transform="translate(-534.75 -529.25)">
      <g transform="translate(138 463)">
        <g transform="translate(223 -523)">
          <path
            className="a"
            d="M8,3A7.557,7.557,0,0,0,.5,10.614h0V12a1,1,0,0,0,1,1h13a1,1,0,0,0,1-1V10.613A7.557,7.557,0,0,0,8,3Z"
            transform="translate(174 587)"
          />
          <path
            className="a"
            d="M6,13v-.5a2,2,0,1,1,4,0V13"
            transform="translate(174 587)"
          />
          <path className="a" d="M9.5,6,8,10.5" transform="translate(174 587)" />
          <path className="a" d="M3.25,10h0" transform="translate(174 587)" />
          <path
            className="a"
            d="M3.25,10a.25.25,0,1,0,.25.25A.25.25,0,0,0,3.25,10"
            transform="translate(174 587)"
          />
          <path className="a" d="M4.25,7.5h0" transform="translate(174 587)" />
          <path
            className="a"
            d="M4.25,7.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25"
            transform="translate(174 587)"
          />
          <path className="a" d="M12.75,10h0" transform="translate(174 587)" />
          <path
            className="a"
            d="M12.75,10a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25"
            transform="translate(174 587)"
          />
          <path className="a" d="M11.75,7.5h0" transform="translate(174 587)" />
          <path
            className="a"
            d="M11.75,7.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25"
            transform="translate(174 587)"
          />
          <path className="a" d="M6.25,5.5h0" transform="translate(174 587)" />
          <path
            className="a"
            d="M6.25,5.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25"
            transform="translate(174 587)"
          />
        </g>
      </g>
    </g>
  </StyledSvg>
);

export default AutoDriveChangesSvg;
