import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import configureStore from "./store/configureStore";
import App from "./containers/App";
import IntlProviderWrapper from "./i18n/intlProviderWrapper";


const store = configureStore();

const NextApp = () => {

  return (
    <Provider store={store}>
      <IntlProviderWrapper>
        {(restProps) => (
          <Switch>
            <Route
              path="/"
              render={(props) => <App {...props} {...restProps} />}
                          />
          </Switch>
        )}
      </IntlProviderWrapper>
    </Provider>
  );
};

export default NextApp;
