import { createSlice } from "@reduxjs/toolkit";
import {
  coreShopStatus,
  nonCoreShopStatus,
} from "../../routes/shops/common/helper";
import moment from "moment";

const userSessionsSlice = createSlice({
  name: "userSessions",
  initialState: {
    filters: {
      shops: {},
      equipment: {},
      machineConfigration:{},
      utility:{},
      pumpSettings:{},
      uploadServicePack:{},
      logFiles: {},
      logRequest:{},
      customer:{},
      configurationMaps:{}
    },
    mapLegends: {
      core: ["All", ...coreShopStatus],
      nonCore: ["All", ...nonCoreShopStatus],
    },
    machineSeries: null,
    timelinePageSize: null,
    timelinePageValue:1,
    timelineFiteredevents: [],
    language: null,
    preferences: {
      timeZone: "",
      dateFormat: "",
      numberFormat: "",
      timeFormat: "hh:mm",
    },
  },
  reducers: {
    setFilters: (userSessions, action) => {
      const { page, filters, sorter } = action.payload;
      userSessions.filters[page].filters = filters;
      userSessions.filters[page].sorter = sorter;
    },
    setTimelinePageSize: (userSessions, action) => {
      userSessions.timelinePageSize = action.payload;
    },
    setTimelinePageValue: (userSessions, action) => {
      userSessions.timelinePageValue = action.payload;
    },
    setFilteredEvents: (userSessions, action) => {
      userSessions.timelineFiteredevents = action.payload;
    },
    setLanguage: (userSessions, action) => {
      userSessions.language = action.payload;
    },
    setUserPreferences: (userSessions, action) => {
      const { timeZone } = action.payload;
      if(!timeZone || timeZone == ""){
        action.payload.timeZone = moment.tz.guess();
      }
      userSessions.preferences = action.payload;
    },
    setMapLegends: (userSessions, action) => {
      const { tier, data } = action.payload;
      userSessions.mapLegends[tier] = data;
    },
    setMachineSeries: (userSessions, action) => {
      userSessions.machineSeries = action.payload;
    }
  },
});

export const {
  setFilters,
  setServiceKpis,
  setTimelinePageSize,
  setTimelinePageValue,
  setFilteredEvents,
  setLanguage,
  setUserPreferences,
  setMapLegends,
  setMachineSeries
} = userSessionsSlice.actions;
export default userSessionsSlice.reducer;
