import { combineReducers } from "redux";
import machineSeries from "./machineSeries";
import servicePackStatus from "./servicePackStatus";
import timelineEvents from "./timelineEvents";
import handleAlerts from "./handleAlertsData";
import servicePackAvailability from "./servicePackAvailability";
import machineList from "./machineList";
import userSessions from "./userSessions";
import Machines from "./machines";
import shopDetails from "./shopsDetail";
import shopGroupAndShopHierarchy from "./shopGroupAndShopHierarchy";
import tiers from "./tiers";
import shopGroups from "./shopGroups";
import licenseList from "./licenseList";
import powerBICreds from "./powerBICreds";
import customers from "./customers";
import pumpSettings from "./pumpSettings";

export default combineReducers({
  machineSeries,
  servicePackStatus,
  timelineEvents,
  handleAlerts,
  servicePackAvailability,
  machineList,
  userSessions,
  shopDetails,
  Machines,
  shopGroupAndShopHierarchy,
  tiers,
  shopGroups,
  licenseList,
  powerBICreds,
  customers,
  pumpSettings
});
