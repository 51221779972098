import React from "react";
import { Range, getTrackBackground } from "react-range";
import styled, { withTheme } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2em 2em 5em;
`;

const RangeSlider = ({
  values = [],
  currentValue,
  step = 1,
  min = 0,
  max = 100,
  markStep = 10,
  thumbColors = ["#707070", "#707070"],
  trackColors = ["#AAA", "#AAA", "#AAA"],
  disabled = true,
  labels = [],
  currentValueText,
  ...rest
}) => {
  return (
    <Container>
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        renderMark={({ props, index }) => (
          <>
            {currentValue !== null && currentValue !== undefined &&
            (currentValue === index * step ||
              currentValue === index ||
              (currentValue % step !== 0 &&
                currentValue > index * step &&
                currentValue < (index + 1) * step)) ? (
              <div
                {...props}
                style={{
                  ...props.style,
                  width: 4,
                  borderTop: `30px solid #AAAAAA`,
                  marginTop: "5px",
                  display: "block",
                }}
              >
                {/* <DownArrow /> */}
                <div
                  style={{
                    marginLeft: "-3px",
                    display: "inline-block",
                    textAlign: "center"
                  }}
                >
                  {currentValue}
                  <div
                    style={{
                      marginLeft: -38,
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    {currentValueText}
                  </div>
                </div>
              </div>
            ) : (
              <div
                {...props}
                style={{
                  ...props.style,
                  width: 0,
                  height: 0,
                  borderLeft: "5px solid transparent",
                  borderRight: "5px solid transparent",
                  borderTop: "6px solid #d4cfcf",
                  tranform: "translate(0, 10px)",
                  marginTop: "5px",
                  marginLeft: index === max / step ? "-5px" : "5px",
                  display:
                    (index * step) % markStep === 0 ||
                    index === max ||
                    index === min
                      ? "block"
                      : "none",
                }}
              >
                <span
                  style={{
                    marginLeft: "-5px",
                    marginTop: 3,
                    display: "inline-block",
                  }}
                >
                  {parseFloat(index * step)}
                </span>
              </div>
            )}
          </>
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: values,
                  colors: trackColors, //["red", "orange", "green", "red"],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, value, index, isDragged }) => (
          <>
            <div
              {...props}
              style={{
                ...props.style,
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                backgroundColor:
                  thumbColors.length === 1
                    ? thumbColors[0]
                    : thumbColors[index], //index === 0 ? "red" : index === 1 ? "orange" : "green",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //boxShadow: "0px 2px 6px #AAA",
                fontSize: "13px",
                color: "white",
                outline: "none",
                boxShadow: "none",
              }}
            >
              {value}
              <div
                style={{
                  ...props.style,
                  transform: "translateY(-35px)",
                  fontWeight: "500",
                  fontSize: "15px",
                  color:
                    thumbColors.length === 1
                      ? thumbColors[0]
                      : thumbColors[index], //index === 0 ? "red" : index === 1 ? "orange" : "green",
                }}
              >
                {labels[index]}
                {/* index === 0 ? "Crtical" : index === 1 ? "Warning" : "Safe" */}
              </div>
              {isDragged && (
                <div
                  style={{
                    ...props.style,
                    transform: "translateY(-22px)",
                    color: "#d4cfcf",
                  }}
                >
                  {value}
                </div>
              )}
            </div>
          </>
        )}
      />
    </Container>
  );
};

export default withTheme(RangeSlider);
