import jwt_decode from "jwt-decode";
import { tokenIsNotExpired } from "./helper";
import { Apps, Roles } from "./AppsAndRoles";

const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const ID_TOKEN = "id_token";
const SUPER_ADMIN_ROLE = `${Apps.Insight}-${Roles.SuperAdmin}`;
const ADMIN_ROLE = `${Apps.Insight}-${Roles.Admin}`;
const TECHNICAL_ROLE = `${Apps.Insight}-${Roles.Technical}`;
const DATA_HUB_APPLICATION = Apps.DataHub;
const INSIGHT_APPLICATION = Apps.Insight;

export const getAccessTokenFromStorage = () =>
  sessionStorage.getItem(ACCESS_TOKEN);

export const getUserDetailsFromStorage = () => {
  const access_token = getAccessTokenFromStorage();
  const authState = { userName: null, role: null, access_token: null };
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (tokenIsNotExpired(decoded.exp)) {
      authState.userName = decoded.name;
      authState.emailId = decoded.emails[0];
      authState.role = decoded.extension_Roles;
      authState.access_token = access_token;
      authState.companyName = decoded.extension_CustomerId;
    }
  }
  return authState;
};

export const getCompanyDetails = () => {
  const access_token = getAccessTokenFromStorage();
  let companyName = "";
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (tokenIsNotExpired(decoded.exp)) {
      companyName = decoded.extension_CustomerId;
    }
  }
  return companyName;
};

export const getEmailAndInitials = () => {
  const access_token = getAccessTokenFromStorage();
  let initials = "",
    emailId = "";
  if (access_token) {
    const { exp, given_name, family_name, emails } = jwt_decode(access_token);
    if (tokenIsNotExpired(exp)) {
      let result =
        given_name && family_name
          ? given_name.charAt(0) + family_name.charAt(0)
          : emails[0].substring(0, 2).toUpperCase();
      initials = result;
      emailId = emails[0];
    }
  }
  return { emailId, initials };
};

export const clearTokensFromStorage = () => {
  sessionStorage.clear();
};

export const clearUserColumnDataFromStorage = () => {
  let options = [];
  localStorage.setItem("userColumnOptions", JSON.stringify(options))
}

export const setTokensInStorage = (accessToken, idToken) => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.includes("refreshtoken")) {
      sessionStorage.setItem(
        REFRESH_TOKEN,
        JSON.parse(sessionStorage.getItem(key)).secret
      );
    } else if (key.includes("idtoken")) {
      sessionStorage.setItem(ID_TOKEN, idToken);
    } else if (key.includes("accesstoken"))
      sessionStorage.setItem(ACCESS_TOKEN, accessToken);
  });
};

export const doesUserHasAccessToAdminPanel = () => {
  const access_token = getAccessTokenFromStorage();
  if (localStorage.getItem("byPassLogin") === "true") return true;
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (decoded.extension_Roles) {
      return (
        decoded.extension_Roles.includes(ADMIN_ROLE) ||
        decoded.extension_Roles.includes(SUPER_ADMIN_ROLE)
      );
    }
  }
  return false;
};

export const doesUserHasOnlyAdminRole = () => {
  const access_token = getAccessTokenFromStorage();
  if (localStorage.getItem("byPassLogin") === "true") return true;
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (decoded.extension_Roles) {
      return (
        decoded.extension_Roles.includes(ADMIN_ROLE) ||
        decoded.extension_Roles.includes(SUPER_ADMIN_ROLE)
      );
    }
  }
  return false;
};

export const doesUserHaveAccessToSuperAdminSettings = () => {
  const access_token = getAccessTokenFromStorage();
  if (localStorage.getItem("byPassLogin") === "true") return true;
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (decoded.extension_Roles) {
      return decoded.extension_Roles.includes(SUPER_ADMIN_ROLE);
    }
  }
  return false;
};

export const doesUserHaveAccesstoDataHubApplication = () => {
  const access_token = getAccessTokenFromStorage();
  if (localStorage.getItem("byPassLogin") === "true") return true;
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (tokenIsNotExpired(decoded.exp)) {
      return decoded.extension_Application.includes(DATA_HUB_APPLICATION);
    }
  }
  return false;
};

export const doesUserHaveAccesstoInsightApplication = () => {
  const access_token = getAccessTokenFromStorage();
  if (localStorage.getItem("byPassLogin") === "true") return true;
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (tokenIsNotExpired(decoded.exp)) {
      return decoded.extension_Application.includes(INSIGHT_APPLICATION);
    }
  }
  return false;
};

export const isUserActive = () => {
  const access_token = getAccessTokenFromStorage();
  if (localStorage.getItem("byPassLogin") === "true") return true;
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (tokenIsNotExpired(decoded.exp)) {
      return decoded.extension_IsUserActive === true;
    }
  }
  return false;
};
export const getTiersOptions = [
  {
    tierName: "TI Color Management",
    value: "basic",
    disabled: true,
  },  
  {
    tierName: "TI Fleet Management",
    value: "fleet",
    disabled: true,
  },
  {
    tierName: "TI Full suite",
    value: "smart",
    disabled: true,
  },
  ];

  export const allNumbFormat = [
    "##,##,##,###",
    "##,##,##,###.#",
    "##,##,##,###.##",
    "###,###,###.#",
    "###,###,###.##"
  ]

export const isTechnicalUser = () => {
  const access_token = getAccessTokenFromStorage();
  if (localStorage.getItem("byPassLogin") === "true") return true;
  if (access_token) {
    const decoded = jwt_decode(access_token);
    if (decoded.extension_Roles) {
      return (
        decoded.extension_Roles.includes(TECHNICAL_ROLE)
      );
    }
  }
  return false;
}