import React from "react";
const CompassSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g transform="translate(25.999)">
      <path
        fill="#262626"
        d="M56.577,55.218,49.648,57.91A.755.755,0,0,0,49.6,59.3l2.636,1.238,1.238,2.636a.755.755,0,0,0,.684.434h.026a.755.755,0,0,0,.678-.481L57.554,56.2a.755.755,0,0,0-.978-.978Zm-2.483,5.715-.6-1.287a.755.755,0,0,0-.363-.363l-1.287-.6,3.686-1.432Z"
        transform="translate(-69.717 -49.054)"
      />
      <path
        fill="#262626"
        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.405A8.405,8.405,0,1,1,18.406,10,8.415,8.415,0,0,1,10,18.405Z"
        transform="translate(-26)"
      />
    </g>
  </svg>
);

export default CompassSvg;
