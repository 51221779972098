/**
 * Apps and Roles constants which will be set for each user in the JWT Token.
 */

export const Apps = {
  Insight: "TIInsight",
  DataHub: "TIDataHub",
  Dispense: "TIDispense",
  Service: "TIService",
};

export const Roles = {
  Basic: "Basic",
  Admin: "Admin",
  SuperAdmin: "SuperAdmin",
  Technical: "Technical"
};
