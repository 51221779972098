import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../common/api";
import { notifier } from "@tintelligence/components";
import {
  format,
  setDate,
  getDate,
  getMonth,
  getYear,
  differenceInMinutes,
} from "date-fns";
import moment from "moment";
import { camelCase } from "lodash";

const getUTCDate = (date) => {
  return setDate(new Date(getYear(date), getMonth(date), 1), getDate(date));
};
function decapitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const getAlertListlineData = (data) => (dispatch, getState) => {
  const {
    selectedMachineSeries,
    startDate,
    endDate,
    machineSeriesList,
    selectedTier,
    selectedCustomerValue
  } = data;
  const currentState = getState().entities.handleAlerts.alertListData;

  const fetchAlertListData = async () => {
    !Number.isNaN(startDate) &&
      !Number.isNaN(endDate) &&
      dispatch(
        apiCallBegan({
          url: `/ms/api/handlealerts/alertlist/${selectedMachineSeries}/${startDate}/${endDate}/${selectedTier}?selectedCustomerId=${selectedCustomerValue}`,
          method: "get",
          onSuccess: eventDataReceived,
          onReturnProps: {
            selectedMachineSeries,
            startDate,
            endDate,
            machineSeriesList,
            selectedTier,
          },
          onError: eventDataRequestFailed,
        })
      );
  };

  fetchAlertListData();
  // if (currentState[selectedMachineSeries]?.lastTier == selectedTier) {
  //   if (
  //     currentState[selectedMachineSeries]?.startDate <= startDate &&
  //     endDate <= currentState[selectedMachineSeries]?.endDate
  //   ) {
  //     const current = new Date();
  //     if (
  //       differenceInMinutes(
  //         current,
  //         currentState[selectedMachineSeries].lastSync
  //       ) > 1
  //     ) {
  //       fetchAlertListData();
  //     }
  //   } else {
  //     fetchAlertListData();
  //   }
  // } else {
  //   fetchAlertListData();
  // }
};

const handleAlertsSlice = createSlice({
  name: "handleAlerts",
  initialState: {
    count: null,
    fleetStatistics: null, //all
    machineSeriesStatistics: {}, //machineSeriesStatistics
    KPIAlertsSummary: {}, //alertSummmary
    alertListData: {}, //alertListData
  },
  reducers: {
    setHandleAlertsCount24hrs: (handleAlerts, action) => {
      const { count } = action.payload;
      handleAlerts.count = count;
    },
    setFleetStatistics: (handleAlerts, action) => {
      handleAlerts.fleetStatistics = action.payload;
    },
    setMachineSeriesStatistics: (handleAlerts, action) => {
      const { selectedMachineSeries, data } = action.payload;
      handleAlerts.machineSeriesStatistics[selectedMachineSeries] = data;
    },
    setKPIAlertsSummary: (handleAlerts, action) => {
      const { selectedMachineSeries, data, selectedTier } = action.payload;
      handleAlerts.KPIAlertsSummary[selectedMachineSeries] = data;
    },
    eventDataReceived: (handleAlerts, action) => {
      const { data } = action.payload;
      const {
        selectedMachineSeries,
        startDate,
        endDate,
        machineSeriesList,
        selectedTier,
      } = action.payload.onReturnProps; //
      let sync = new Date();

      for (let a in data) {
        data[a]["alertType"] = camelCase(data[a]["alertType"]);
        data[a]["kpi"] = decapitalizeFirstLetter(data[a]["kpi"]);
      }

      if (selectedMachineSeries == 0) {
        let machinesObj = {};
        for (let a in data) {
          let rest = machinesObj[data[a].machineSeriesRowId] || [];
          machinesObj[`${data[a].machineSeriesRowId}`] = [...rest, data[a]];
        }
        for (let i = 0; i < machineSeriesList.length; i++) {
          if (machinesObj[machineSeriesList[i].id]?.length > 0) {
            handleAlerts.alertListData[machineSeriesList[i].id] = {
              data: machinesObj[machineSeriesList[i].id] || [],
              lastSync: sync,
              dataValidToDisplay: true,
              startDate: startDate,
              endDate: endDate,
              lastTier: selectedTier,
            };
          }
        }
      }

      handleAlerts.alertListData[selectedMachineSeries] = {
        data: data,
        lastSync: sync,
        dataValidToDisplay: true,
        startDate: startDate,
        endDate: endDate,
        lastTier: selectedTier,
      };
    },
    eventDataRequestFailed: (handleAlerts, action) => {
      notifier.warn("Error occured while fetching data");
    },
  },
});

export const {
  setHandleAlertsCount24hrs,
  setFleetStatistics,
  setMachineSeriesStatistics,
  setKPIAlertsSummary,
  eventDataReceived,
  eventDataRequestFailed,
} = handleAlertsSlice.actions;
export default handleAlertsSlice.reducer;
