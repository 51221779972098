import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        font-family: ${(props) => props.theme.font.fontFamily};
    }
    & a {
        color: ${(props) => props.theme.mode.secondary};
        :hover {
            color: ${(props) => props.theme.mode.secondary};
        }
    }
    & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: #fff;
    }
    & .ant-drawer-title {
        font-size: 22px;
    }
    & .accordionTabs-tabs {
        & .ant-tabs-tab { text-align: left !important; border: 1px solid transparent !important; }
        & .ant-tabs-tab-active { border-right: ${(props) =>
          `5px solid ${props.theme.mode.secondary} !important`} }
        & .ant-tabs-ink-bar { background: transparent !important; }
    }
    & .shop-details {
        .ant-drawer-close {
            z-index: 11;
            background: ${(props) => props.theme.mode.secondary} !important;
            border-radius: 50%;
            padding: 10px;
            margin-top: 10px;
            margin-right: 20px;
            width: inherit !important;
        }
        .ant-drawer-body {
            z-index: 10;
            margin-top: -69px;
            padding: 0 !important;
        }
        .ant-drawer-footer {
            height: 5px;
        }
    }
    & .ant-card {
        color: ${(props) => props.theme.mode.primaryText};
    }
    & .ant-tabs {
        color: ${(props) => props.theme.mode.primaryText};
    }
    & .appsDropDownContainer {
        & .ant-dropdown-arrow {
            border-top-color: ${(props) => props.theme.mode.secondary};
            border-left-color: ${(props) => props.theme.mode.secondary};
            :before { background: ${(props) => props.theme.mode.secondary}; }
        } 
        & .ant-dropdown-menu.ant-dropdown-menu-root {
            background-color: ${(props) => props.theme.mode.secondary};
        }
        & .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
            background-color: ${(props) => props.theme.mode.activeSecondary};
        } 
    }

    & .form-only-label {
        & .ant-form-item-control { display: none; }
    }
    & .form-only-label-float-right {
        & .ant-form-item-row {
             display: flex !important; flex-direction: row !important; 
            }
    }
    & .ant-drawer-header-title {
      align-items: baseline;
    }
    &.ant-table-body{
        overflow: auto !important;
    }
    .ant-btn-secondary:hover:disabled{
        background-color: #d9d9d9 !important;
                border: #d9d9d9 !important;
    }
    .ant-btn-primary:hover:disabled{
        background-color: #d9d9d9 !important;
        border: #d9d9d9 !important;
    }
    .ant-btn-primary:disabled{
        background-color: ${(props) => props.theme.mode.disabled} !important;
        border: #d9d9d9 !important;
    }
    .ant-btn-secondary:disabled{
        background-color:${(props) => props.theme.mode.disabled} !important;
        border:${(props) => props.theme.mode.disabled} !important;
    }
    & .ant-table-filter-dropdown-tree {
        & .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
            background-color: ${(props) => props.theme.mode.secondary};
        }

        & .ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
            color: ${(props) => props.theme.mode.secondary};
        }
     }
    & .ant-tree-indent-unit {
        width: 5px;
    }


    //
    & .ant-steps-item-tail::after { background: #b7b7f5 !important; }
    & .ant-steps-item-icon { 
        background: #b7b7f5 !important; 
        border: #fff !important; 
        font-weight: 600;
        & svg {
            fill:  #fff !important; 
        } 
        & .ant-steps-icon { color: #3e36a6 !important;  }
    }
    & .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
      color: #3e36a6 !important; 
    }
    & .ant-steps-item-content {
        min-height: 100px !important;
        & .ant-steps-item-title { font-weight: 600; }
        & .ant-steps-item-title, .ant-steps-item-description {
            color: #fff !important;
        }
    }
    //

    ::-webkit-scrollbar {
        width: 10px; //wider scrollbar move later in uicomponents
    }
    //
    .ant-select-dropdown-hidden {
        display: block;
        visibility: hidden;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: ${(props) => props.theme.mode.secondary};
    }
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
        border-color: ${(props) => props.theme.mode.secondary};
        box-shadow: 0 0 0 2px #635ad97a;
    }
    & .ant-layout-sider-children {
        background: ${(props) => props.theme.mode.secondary};
        background-image: url("/Left_Graphic_BG.svg");
        background-position: bottom;
        background-repeat: no-repeat;
        flex: 0 0 65px !important;
        max-width: 65px !important;
        min-width: 65px !important;
        width: 65px !important;

    }
    & .tooltip-topRight-arrow-hide.ant-tooltip-placement-topRight .ant-tooltip-arrow,
    .tooltip-topRight-arrow-hide.ant-tooltip-placement-bottomRight .ant-tooltip-arrow
    {
       display:none;
    //right: -3px;
    }
    & #table-icons .anticon:hover { & svg, & svg > path { 
       fill: ${(props) => props.theme.mode.secondary} 
   }}

   div:focus-visible, img:focus-visible, span[role='img']:focus-visible {
    outline:${(props) => `${props.theme.mode.secondary} auto 1px`};
      outline-offset: 2px;
    }
   a:focus-visible, button:focus-visible, .ant-btn-primary:focus-visible, .ant-btn-secondary:focus-visible {
    outline:${(props) => `${props.theme.mode.secondary} auto 1px`};
    outline-offset: 4px;
    }
    .ant-form-item .ant-form-item-explain-error {
        white-space: break-spaces;
    }
    th:focus-visible, .ant-table-column-title:focus-visible, .pack-file-selection:focus-visible
        {
            outline:${(props) =>
              `${props.theme.mode.secondary} auto 1px !important`};
        }

// :FOCUS CSS START
    span[role='img']:focus , span[role='img']:focus 
        {
            outline:${(props) =>
              `${props.theme.mode.secondary} auto 1px !important`};
            outline-offset: 1px !important;
        }
   .ant-drawer-footer span button:focus, .ant-drawer-close:focus, .ant-modal-close:focus
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px`};
            border-color:${(props) =>
              `${props.theme.mode.secondary} !important`};
            outline-offset: -4px !important;
        }
        .focus_visible:focus   
        //,.ant-btn:not(.ant-drawer-footer .ant-btn-primary):focus
        {
            outline:${(props) =>
              `${props.theme.mode.secondary} auto 1px !important`};
            outline-offset: 4px !important;
        }
        .ant-tooltip-inner {
            z-index: 1000;
        }   
        .ant-switch-inner:not(.ant-switch-inner-checked):hover {
            background-color: ${(props) =>
              props.theme.mode.disabled} !important;
     }     
// :FOCUS CSS END

// tooltip 
.ant-tooltip {
    --antd-arrow-background-color: ${(props) => props.theme.mode.secondary};
}
.clickableDropdown{
    width: 100%;
    display: block;
}
.ant-select-dropdown .ant-select-item-option-content {
    float: left;
    flex: unset;
    /* display: flex; */
    overflow: hidden;
    align-items: flex-start;
    white-space: unset;
    text-overflow: ellipsis;
    width: 100%;
}
// virtual table css
    .ant-table-wrapper .ant-table-tbody-virtual .ant-table-row{
        font: 500 14px / 16px Montserrat;
    }
    .ant-select-single{
        height:100%
    }
    
    
    .ant-select-multiple .ant-select-selection-item.ant-select-selection-item-disabled {
        background: rgb(234 234 238);
        border: 1px solid rgb(162 162 162) !important;
    }
`;

export default GlobalStyle;
