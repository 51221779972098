import axios from "axios";
import { getAccessTokenFromStorage, setTokensInStorage } from "./principal";
import { notifier } from "@tintelligence/components";
import jwt_decode from "jwt-decode";
import { tokenIsNotExpired } from "./helper";
import { IntlProvider } from "react-intl";

import { defualtLocale as locale, messages } from "../i18n/intlProviderWrapper";
import { acquireTokenSilently } from "./auth-util";
import { now } from "lodash";

const intlProvider = new IntlProvider({ locale, messages });
const apiMessages = intlProvider.props.messages[locale];

const apiCalls = [];

const checkEarlierCalls = (apiArr, url, time) => {
  let flag;
  let ele = apiArr.findLast((a) => a.url == url);
  //ele && console.log(time - ele.time);
  ele && time - ele.time < 2000 ?  flag = true : flag = false;
  return flag;
} // removed for now due to clashesh with assets breaking

axios.interceptors.response.use(null, (error) => {
  const errorCode = error.response && error.response.status;
  const expectedError = errorCode >= 400 && errorCode <= 500;
  if (errorCode === 401) {
    notifier.warn(apiMessages.menu.apiCommonMessages.unauthorised);
    //return Promise.reject(error);
  } else if (!expectedError) {
    console.error(error);
    //message.error("An unexpected error occurrred.");
    return Promise.reject(error);
  }
  return Promise.reject({ status: errorCode, data: error?.response?.data }); //Check
});

axios.interceptors.request.use(
  async (request) => { 
    apiCalls.push({ url: request.url, time: now() })
    //console.log('axios call', apiCalls)
    const access_token = getAccessTokenFromStorage();
    const access_token_expiry = jwt_decode(access_token).exp;
    if (!tokenIsNotExpired(access_token_expiry)) {
      const { emails } = jwt_decode(access_token);
      const { accessToken, idToken } = await acquireTokenSilently(emails[0]);
      setTokensInStorage(accessToken, idToken);
    }
    
    // if(request.url.indexOf('contractmanagement/getPreferredTier')>0 || request.url.indexOf('shop/')>0 || request.url.indexOf('shopgroup/')>0  )
    // request.url = "https://localhost:44356" + request.url;
    // else if(request.url.indexOf('servicepack/machineSeriesServicePackAvailability') >0 || request.url.indexOf('machineseries/inUseMachineSeriesList') >0 )
    // request.url = "https://localhost:44303" + request.url;
    // else
    request.url = process.env.REACT_APP_BASE_URL + request.url;
    request.headers.common[
      "Authorization"
    ] = `Bearer ${getAccessTokenFromStorage()}`;
    request.headers.common["Ocp-Apim-Subscription-Key"] =
      process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
    
      
    return request;
  },
  function (err) {
    console.log(err);
    notifier.warn("error");
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
