import moment from "moment";

export const compareDateStings = (d1, d2) => {
  const dateA = moment(d1, "DD/MM/YYYY"),
    dateB = moment(d2, "DD/MM/YYYY");
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

export const formatDate = (date, format) => {
  //return moment(date, format);
  return moment(date).format(format);
};

export const compareFormatedDates = (d1, d2, format) => {
  const dateA = moment(d1, format),
    dateB = moment(d2, format);
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

export const scrollComponents = (parent, scroll) => {
  const el = parent.target,
    scrollTop = el.scrollTop;

  el.querySelectorAll(scroll).forEach((child) => {
    child.style.transform = `translateY(${scrollTop}px)`;
  });
};

export const tokenIsNotExpired = (exp) => Number(exp) * 1000 > Date.now();

export const formatMachineTypeForImage = (machine) =>
  machine.replace(/[|~\/]/g, "-");

export const formatSeriesForServicePack = (machine) =>
  machine.replace(/[\\/]/g, "~");

export const groupBy = (array, property) => {
  var hash = {};
  for (var i = 0; i < array.length; i++) {
    if (!hash[array[i][property]]) hash[array[i][property]] = [];
    hash[array[i][property]].push(array[i]);
  }
  return hash;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getShopGroupFilterTree = (shopGroupsArray) => {
  if (shopGroupsArray == undefined) {
    return [];
  }
  let idPositionMap = {},
    group,
    nestedGroups = [],
    i,
    shopGroups = shopGroupsArray.map((g) => ({ ...g }));

  for (i = 0; i < shopGroups.length; i++) {
    idPositionMap[shopGroups[i].shopGroupId] = i; // initialize the map
    shopGroups[i].children = []; // initialize the children
  }

  for (i = 0; i < shopGroups.length; i++) {
    group = shopGroups[i];
    if (group.parentId !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      shopGroups[idPositionMap[group.parentId]].children.push(group);
    } else {
      nestedGroups.push(group);
    }
  }
  return getFilterTreeShopGroups(nestedGroups);
};

const getFilterTreeShopGroups = (nestedGroups) => {
  return nestedGroups.map((group) => ({
    value: group.shopGroupName,
    text: group.shopGroupName,
    children: getFilterTreeShopGroups(group.children),
  }));
};

export const getAllNumberFormatByLocale = (locale) => {
  const num = 30000.65;
  const numbersArray = [];
  for (let i = 0; i < 3; i++) {
    numbersArray.push(
      num.toLocaleString(locale, {
        minimumFractionDigits: i,
        maximumFractionDigits: i,
      })
    );
  }

  let data = convertToHash(numbersArray).map((element, key) => {
    return {
      label: element,
      value: key,
      key: key,
    };
  });

  return data;
};
const convertToHash = (numbersArray) => {
  const hashedArray = [];
  numbersArray.map((x) => {
    hashedArray.push(x.replace(/[0-9]/g, "#"));
  });
  return hashedArray;
};

export const convertNumberFormat = (userSettings, num) => {
  if (!num) {
    return 0;
  } else {
    return num.toLocaleString(userSettings.language, {
      minimumFractionDigits: userSettings.numberFormat
        ? userSettings.numberFormat
        : 1,
      maximumFractionDigits: userSettings.numberFormat
        ? userSettings.numberFormat
        : 1,
    });
  }
};

export const formatUTCDateBasedOnUser = (value, userSettings) => {
  const fomatedValue =
    value && userSettings
      ? moment
          .utc(value)
          .tz(userSettings.timeZone)
          ?.format(
            userSettings.dateFormat + (userSettings.displayTimezone ? " z" : "")
          )
      : null;
  return fomatedValue;
};
export const formatGMTDateBasedOnUser = (value, userSettings) => {
  const fomatedValue =
    value && userSettings
      ? moment(value)
          .tz(userSettings.timeZone)
          ?.format(
            userSettings.dateFormat + (userSettings.displayTimezone ? " z" : "")
          )
      : null;
  return fomatedValue;
};
export const getFileExtension = (filename) => {
  if (filename) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : `.${ext[1]}`;
  } else return "";
};

export const setFocusOnContentLoad = (container) => {
  window.setTimeout(() => {
    if (!container) {
      container =
        ".ant-layout-content .ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden)";
    }
    const layout = document.querySelector(container);
    //console.log("focus layout container- ", container);
    if (layout) {
      console.log("focus layout container 1- ", container);
      // check any input type
      const inputs = document.querySelectorAll(
        `${container} input[type=text]:not(:disabled), ${container} input[type=search]:not(:disabled)`
      );
      const textArea = document.querySelectorAll(
        `${container} textarea:not(:disabled)`
      );
      const closeDrawerIcon = document.querySelectorAll(
        `${container} .ant-drawer-close`
      );
      const closeModalIcon = document.querySelectorAll(
        `${container} .ant-modal-close`
      );
      const buttons = document.querySelectorAll(
        `${container} .ant-btn:not(:disabled)`
      );

      //console.log("focus layout container- ", container,inputs,
      //textArea,closeDrawerIcon,closeModalIcon,buttons)
      if (inputs && inputs.length > 0) {
        // console.log("focus layout input found")
        // console.log("focus input", inputs[0])
        inputs[0].focus();
      } else if (textArea && textArea.length > 0) {
        // check textarea
        textArea[0].focus();
      } else if (closeDrawerIcon && closeDrawerIcon.length == 1) {
        // check drawer close button
        closeDrawerIcon[0].focus();
      } else if (closeModalIcon && closeModalIcon.length == 1) {
        // check modal close button
        closeModalIcon[0].focus();
      } else if (buttons && buttons.length > 0) {
        // check button
        buttons[0].focus();
      } else {
        //console.log("focus layout not found")
      }
    }
  }, 500);
};
export const setFocusOnElement = (selector, element) => {
  if (!element && selector) element = document.querySelector(selector);
  if (element) {
    element.focus();
  }
};

export const filteredApplicableGeneralCustomerList = (customers, current) => {
  let filtered = [...customers];
  let removed = [];
  customers.map((c, i) => {
    // add to be removed Idex or current customer or service customer
    if (
      c.customerId == "Idex" ||
      c.customerId == current?.customerId ||
      c.type === "Service"
    ) {
      removed.push(c.customerId);
    }
    // add to be removed servicable customers from other service customer
    if (c.type === "Service" && c.customerId != current?.customerId) {
      removed.push([...c.serviceCustomerId]);
    }
  });
  return filtered
    .filter((item) => !removed.includes(item.customerId))
    .map(({ customerId }) => ({ label: customerId, value: customerId }));
};
