import { createSlice } from "@reduxjs/toolkit";

const PumpSettingsSlice = createSlice({
  name: "pumpsettings",
  initialState: {
    saveTrigger:false,
    propertiesValue: {},
    AdvanceValue:{}
  },
  reducers: {
    setPropertyValuesRedux: (state, action) => {       
      Object.assign(state.propertiesValue,action.payload)
    },
    setDefaultPropertyValuesRedux: (state, action) => {       
      state.propertiesValue = action.payload
    },
    setSaveTrigger: (state, action) => {
      state.saveTrigger = action.payload
    },
    setAdvancedValuesRedux: (state, action) => {
      Object.assign(state.AdvanceValue,action.payload)
    }
  },
});

export const { setPropertyValuesRedux, setAdvancedValuesRedux ,setSaveTrigger ,setDefaultPropertyValuesRedux } = PumpSettingsSlice.actions;
export default PumpSettingsSlice.reducer;
