import { createSlice } from "@reduxjs/toolkit";
import { getUserDetailsFromStorage } from "./../../util/principal";
import { combineReducers } from "redux";

const userSlice = createSlice({
  name: "user",
  initialState: getUserDetailsFromStorage(),
  reducers: {
    loggedIn: (user, action) => {
      const {
        userName,
        emailId,
        role,
        access_token,
        companyName,
      } = action.payload;
      user.userName = userName;
      user.emailId = emailId;
      user.role = role;
      user.access_token = access_token;
      user.companyName = companyName;
    },
    loggedOut: (user, action) => {
      user.userName = null;
      user.emailId = null;
      user.role = null;
      user.access_token = null;
      user.companyName = null;
    },
  },
});

export const { loggedIn, loggedOut } = userSlice.actions;

export default combineReducers({ user: userSlice.reducer });
