import { createSlice } from "@reduxjs/toolkit";

const tierSlice = createSlice({
  name: "Tiers",
  initialState: { hightestTier: null, selectedTier : null },
  reducers: {
    setHightestTier: (tiers, action) => {
      tiers.hightestTier = action.payload;
    },
    setSelectedTier: (tiers, action) => {
        tiers.selectedTier = action.payload;
      },
  },
});

export const { setHightestTier,setSelectedTier } = tierSlice.actions;
export default tierSlice.reducer;
