import { createSlice } from "@reduxjs/toolkit";

const CustomersSlice = createSlice({
  name: "customers",
  initialState: { selectedCustomer: "", list: [] },
  reducers: {
    setSelectedCustomer: (customers, action) => {
      console.log("customer in store",action.payload);
      customers.selectedCustomer = action.payload;
    },
    setCustomersList: (customers, action) => {
      customers.list = action.payload;
    },
  },
});

export const { setSelectedCustomer, setCustomersList } = CustomersSlice.actions;
export default CustomersSlice.reducer;
