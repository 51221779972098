import { createSlice } from "@reduxjs/toolkit";
import { differenceInMinutes } from "date-fns";
import { notifier } from "@tintelligence/components";
import { apiCallBegan } from "../common/api";

export const getShopsData =
  (tier, reloadData = false, selectedCustomer) =>
  (dispatch, getState) => {
    const currentState = getState().entities.shopDetails;
    const fetchShopListData = async () => {
      //use of async?
      dispatch(
        apiCallBegan({
          url: `/om/api/shop/shopDetails/${tier}?selectedCustomerId=${selectedCustomer}`,
          method: "get",
          onSuccess: setShopList,
          onReturnProps: { tier, selectedCustomer },
          onError: requestFailed,
        })
      );
    };

    if (
      differenceInMinutes(
        new Date(),
        currentState[tier]?.[selectedCustomer]?.lastSync
      ) > 1
    ) {
      fetchShopListData();
    } else if (reloadData || !currentState[tier]?.[selectedCustomer]?.list) {
      fetchShopListData();
    }
  };

const shopListSlice = createSlice({
  name: "shopDetails",
  initialState: {
    core: {},
    smart: {},
    elite: {},
    fleet:{},
    basic:{}
  },
  reducers: {
    setShopList: (shopDetails, action) => {
      const { data, onReturnProps } = action.payload;
      // shopDetails[onReturnProps.tier].list = data;
      // shopDetails[onReturnProps.tier].lastSync = new Date();
      return {
        ...shopDetails,
        [onReturnProps.tier]: {
          ...shopDetails[onReturnProps.tier],
          [onReturnProps.selectedCustomer]: {
            list: data,
            lastSync: new Date(),
          },
        },
      };
    },
    deleteShopFromList: (shopDetails, action) => {
      const { tier, data, selectedCustomer } = action.payload;
      if (shopDetails[tier]?.[selectedCustomer]?.list && shopDetails[tier]?.[selectedCustomer]?.list.length > 0) {
        const updatedData = shopDetails[tier][selectedCustomer].list.filter(
          (d) => {
            if (d.shopRowId !== data.shopRowId) return d;
          }
        );
        return {
          ...shopDetails,
          [tier]: {
            ...shopDetails[tier],
            [selectedCustomer]: {
              list: updatedData,
              lastSync: new Date(),
            },
          },
        };
      }
    },
    updateShopInList: (shopDetails, action) => {
      const { data } = action.payload;

      if (shopDetails.list.length > 0) {
        const shopData = shopDetails.filter((d) => {
          if (d.shopRowId === data.shopRowId) return data;
          else return d;
        });
        shopDetails.list.splice(0, shopData.length, ...shopData);
      } else {
        shopDetails.list = [data];
      }
    },
    clearShopList: (shopDetails, action) => {
      const { tier, selectedCustomer } = action.payload;
      return {
        ...shopDetails,
        [tier]: {
          ...shopDetails[tier],
          [selectedCustomer]: {}
        },
      };
    },
    requestFailed: (shopDetails, action) => {
      notifier.warn("Error occured while fetching data");
    },
  },
});

export const {
  setShopList,
  deleteShopFromList,
  updateShopInList,
  requestFailed,
  clearShopList,
} = shopListSlice.actions;
export default shopListSlice.reducer;
