import React from "react";
import Icon from "@ant-design/icons";
import ReceiveFileSvg from "./ReceiveFileSvg";

const ReceiveFileIcon = (props) => (
  <Icon
    component={() => <ReceiveFileSvg {...props} />}
    {...props}
  />
);

export default ReceiveFileIcon;
