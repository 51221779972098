import React from "react";
import { Range, getTrackBackground } from "react-range";
import styled, { withTheme } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2.3em 0.5em;
`;

const UpArrow = styled.div`
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #0d8aff;
  margin-left: -0.17rem;
  margin-top: -1px;
`;

const DisabledMiniDisplaySlider = ({
  values = [],
  currentValue,
  step = 1,
  min = 0,
  max = 100,
  colors = [],
  labels = [],
  ...rest
}) => {
  const trackColors = ["#AAAAAA", "#AAAAAA", "#AAAAAA"];
  return (
    <Container className="mini-slider">
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        disabled={true}
        renderMark={({ props, index }) => (
          <>
            {currentValue !== null && currentValue !== undefined &&
            (currentValue === index * step ||
              currentValue === index ||
              (currentValue % step !== 0 &&
                currentValue > index * step &&
                currentValue < (index + 1) * step)) ? (
              <div
                {...props}
                style={{
                  ...props.style,
                  width: 4,
                  borderBottom: `30px solid #707070`,
                  marginTop: "-50px",
                }}
              >
                <div
                  style={{
                    marginLeft: "-3px",
                    fontSize: "13px",
                  }}
                >
                  {currentValue}
                </div>
                {/* <UpArrow /> */}
              </div>
            ) : (
              (index * step === min || index * step === max) && (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    width: 0,
                    height: 0,
                    //   borderLeft: "5px solid transparent",
                    //   borderRight: "5px solid transparent",
                    //   borderTop: "6px solid #d4cfcf",
                    tranform: "translate(0, 10px)",
                    marginTop: "5px",
                    marginLeft: index === max / step ? "-5px" : "5px",
                    //   display: (index * step) % 10 === 0 ? "block" : "none",
                  }}
                >
                  <span style={{ marginLeft: "-10px" }}>
                    {index * step}
                  </span>
                </div>
              )
            )}
          </>
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: values,
                  colors: trackColors, //["red", "orange", "green", "red"],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, value, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              width: 0,
              height: 0,
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: `6px solid #707070`,
              tranform: "translate(0, 10px)",
              marginTop: "5px",
              marginLeft: index === max / step ? "-5px" : "5px",
              fontSize: "13px",
            }}
          >
            <div style={{ marginLeft: "-6px" }}>{value}</div>
            <div
              style={{
                transform: `translate(${
                  index % 2 === 0 ? "-6px" : "6px"
                }, -5px)`,
                fontWeight: "500",
                fontSize: "12px",
                marginLeft: "-15px",
                color: '#707070',
              }}
            >
              {labels[index]}
              {/* index === 0 ? "Crtical" : index === 1 ? "Warning" : "Safe" */}
            </div>
          </div>
        )}
      />
    </Container>
  );
};

export default withTheme(DisabledMiniDisplaySlider);
