import { createSlice } from "@reduxjs/toolkit";

const gridHeightSlice = createSlice({
  name: "gridHeight",
  initialState: { state: 0 },
  reducers: {
    setGridHeight: (gridHeight, action) => {
      const { state } = action.payload;
      gridHeight.state = state;
    },
  },
});

export const { setGridHeight } = gridHeightSlice.actions;
export default gridHeightSlice.reducer;
