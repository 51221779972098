import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "loader",
  initialState: { state: 0, text: "" },
  reducers: {
    incrementLoaderCount: (loader, action) => {
      loader.state++;
      loader.text = action.payload?.text;
    },
    decrementLoaderCount: (loader, action) => {
      loader.state--;
      loader.text = action.payload?.text;
    },
  },
});

export const { incrementLoaderCount, decrementLoaderCount } =
  loaderSlice.actions;
export default loaderSlice.reducer;
